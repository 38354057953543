.detailed-search-container {
  width: 100%;
  padding: 10px 0 5px 0;
}

.Filter__select-input,
.Filter__button-input {
  width: 260px;
}

.detailed-search-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.Filter__filters {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.Filter__button-container {
  display: flex;
}

@media (max-width: 1150px) {
  .detailed-search-form {
    justify-content: center;
  }

  .Filter__button-container {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 855px) {
  .Filter__filters {
    flex-direction: column;
  }
}

.detailed-search-form-body {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
}

.Filter__container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.filter {
  width: 100%;
  position: relative;
  display: inline-flex;
  justify-content: flex-end;
}

@media (max-width: 480px) {
  .filter {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.filter__dropdown {
  width: 100%;
  min-width: 300px;
  overflow: hidden;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  z-index: 1;
  right: 0;
  max-height: calc(100vh - 152px);
  background-color: #f6fafd;
  border-radius: 7px;
  border: 1px solid #bcbcbc;
}

@media (max-width: 480px) {
  .filter__dropdown {
    width: 100%;
  }
}

.Filter__button {
  background-color: #985df5;
  border-radius: 5px;
  border: none;
  height: 46px;
}

.Filter__button_content {
  width: 100px;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
}

.Filter__button_icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Filter__button_icon svg {
  width: 22px;
  height: 22px;
}

.Filter__button_text {
  font-size: 0.9rem;
  font-weight: 600;
}

.Filter__dropdown_header {
  border-bottom: 1px solid #bcbcbc;
  background-color: #edf2f6;
  max-width: 100%;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.filter__dropdown_title {
  font-size: 1.1rem;
  font-weight: 600;
}
