.container-home {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px;
}

.Home__hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.Home__hero-title {
  font-weight: 600;
  color: #333;
  font-size: 1.1rem;
}

.Home__hero-title h1 {
  text-align: center;
  margin: 0;
}

.Home__hero-subtitle {
  margin: 0;
  font-weight: 400;
  color: #333;
  font-size: 1rem;
  text-align: center;
}

.Home__hero-subtitle h2 {
  text-align: center;
  margin: 0;
}

@media (max-width: 768px) {
  .Home__hero-title {
    font-size: 0.9rem;
  }

  .Home__hero-subtitle {
    font-size: 0.7rem;
  }
}

@media (max-width: 576px) {
  .Home__hero {
    padding: 10px 0;
  }

  .Home__hero-title {
    font-size: 0.8rem;
  }

  .Home__hero-subtitle {
    font-size: 0.7rem;
  }

  #Home__hero-subtitle-breakpoint {
    display: block;
  }
}

.Home__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.Home__content-card {
  max-width: 600px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);

  padding: 15px 20px;
  /* padding: 14px 80px 18px 36px; */
}

.Home__content-card:hover {
  cursor: pointer;
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.Home__content-card-title h3 {
  font-weight: 600;
  margin: 10px 0;
}

.Home__content-card-subtitle h4 {
  font-weight: 400;
  color: #333;
  font-size: 1.1rem;
  margin: 5px 0;
}

.Home__content-card-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Home__content-card-text {
  padding: 0 0 0 20px;
}

.Home__content-card-container-icon {
}

.Home__content-card-icon {
  background: #007bff;
  border-radius: 100%;

  color: #fff;
  width: 60px !important;
  height: 60px !important;
}

.Home__content-card-icon-whatsapp {
  color: #25d366;
  background: inherit;
}

.Home__content-card-button {
  display: flex;
  justify-content: flex-end;
}
