.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #4d4d4d;
  font-size: 1rem;
  font-weight: 600;
  padding: 20px 0;
  gap: 8px;
}

.footer-links {
  display: flex;
  flex-direction: row;
}

.footer-links > *:not(:last-child):after {
  content: "|";
  margin: 0 5px;
  color: #4d4d4d;
}

.footer-links a {
  text-decoration: none;
  color: #3f51b5;
}

.footer-copyright {
  text-align: center;
}

@media (min-width: 468px) {
  #linebreak-footer {
    display: none;
  }
}
