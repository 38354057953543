.container-tarifas {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.tarifas {
  width: 100%;
  max-width: 1400px;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.detailed-search,
.table-container {
  padding: 14px 0;
}

.detailed-search {
  width: 98% !important;
}

.table,
.toolbar {
  width: 100%;
  max-width: 1400px;
}
