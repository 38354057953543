.Header__container {
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0 20px 0;
  align-items: center;
}

@media (max-width: 1419px) {
  .Header__container {
    padding: 0 10px;
  }
}

.Header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.Header__breadcrumbs_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.Header__title {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 30px !important;
}

@media (max-width: 768px) {
  .Header__title {
    font-size: 24px !important;
  }
}

.Header__breadcrumbs__link,
.Header__breadcrumbs__currentLink {
  color: #4d4d4d;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.Header__breadcrumbs__link svg {
  width: 22px;
  height: 22px;
}

.Header__breadcrumbs__link p {
  font-size: 12pt !important;
}

.Header__breadcrumbs__currentLink {
  color: #000;
}

@media (max-width: 768px) {
  .Header__breadcrumbs__link svg {
    width: 20px;
    height: 20px;
  }

  .Header__breadcrumbs__link p {
    font-size: 9pt !important;
  }
}
