.terms-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 5px;
}

.terms {
  width: 100%;
  max-width: 600px;
}

.Terms__title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
